import { Badge } from '@finalytic/components';
import { paidStatus_enum } from '@finalytic/graphql';
import { ExtendedCustomColors } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';

type Props = {
  paidStatus: Maybe<paidStatus_enum>;
};

const OPTIONS: Record<
  paidStatus_enum,
  {
    label: string;
    color: ExtendedCustomColors;
  }
> = {
  unpaid: {
    label: 'Unpaid',
    color: 'yellow',
  },
  underpaid: {
    label: 'Underpaid',
    color: 'yellow',
  },
  overpaid: {
    label: 'Overpaid',
    color: 'red',
  },
  paid: {
    label: 'Paid',
    color: 'green',
  },
};

export const ReservationPaymentStatusBadge = ({ paidStatus }: Props) => {
  if (!paidStatus) return null;

  const { label, color } = OPTIONS[paidStatus];

  return <Badge color={color}>{label}</Badge>;
};
