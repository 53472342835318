import {
  Badge,
  Button,
  Collapse,
  Filter,
  IconButton,
  Timeline,
  type TimelineItem,
} from '@finalytic/components';
import {
  type TrpcApiError,
  captureSentryError,
  useDashboard,
  useEnabledFeatures,
  useInvalidateQueries,
  useMutation,
  useQuery,
  useReservationsServiceCreateReservationJournalEntries,
  useTeam,
  useTeamRole,
} from '@finalytic/data';
import { HiddenFeatureIndicator, useAutomationModal } from '@finalytic/data-ui';
import type { party_enum } from '@finalytic/graphql';
import {
  CalendarDatesIcon,
  CalendarEventIcon,
  CheckCircleIcon,
  CrossCircleIcon,
  CrossIcon,
  Edit3Icon,
  HomeIcon,
  Icon,
  InfoIcon,
  LoaderIcon,
  RocketIcon,
  UserIcon,
} from '@finalytic/icons';
import { LazyTable, type MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuItem,
  StringParam,
  UrlUpdateType,
  showErrorNotification,
  showSuccessNotification,
  useQueryParamSet,
  useQueryParams,
} from '@finalytic/ui';
import {
  type Maybe,
  day,
  formatCurrency,
  groupBy,
  hasValue,
  sum,
  toTitleCase,
  uniqueBy,
  utc,
} from '@finalytic/utils';
import {
  Alert,
  Anchor,
  Avatar,
  Box,
  Card,
  Center,
  Group,
  LoadingOverlay,
  Progress,
  Stack,
  Tabs,
  Text,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import {
  XIMPLIFI_TENANT_ID,
  formatUserName,
  getListingName,
  getTransactionLineType,
  queryReservationFinancials,
  queryReservationPaymentLines,
} from '@vrplatform/ui-common';
import { type ComponentProps, useMemo, useState } from 'react';
import { ReservationStatusBadge } from '../../components';
import { ReservationPaymentStatusBadge } from '../../components/ReservationPaymentStatusBagde';
import { useFileDownload } from '../../hooks';
import { SelectPostAutomationModal } from '../../modals';
import { generalLedgerSorting } from '../../views/general-ledger/useGeneralLedgerDetailTableQuery';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useDepositDetailDrawer } from '../deposit-drawer';
import { useExpenseDetailDrawer } from '../expense-drawer';
import { ReservationEditForm } from './ReservationEditForm';
import { ReservationLineDeleteModal } from './ReservationLineDeleteModal';
import { ReservationLineFormModal } from './ReservationLineFormModal';

type View = 'overview' | 'edit';

export function useReservationDetailDrawer() {
  const [opts, set] = useQueryParams({
    reservation: StringParam,
    view: StringParam,
  });

  return {
    opened: !!opts.reservation,
    open: (
      id: string,
      view?: 'overview' | 'edit',
      updateType?: UrlUpdateType
    ) => set({ reservation: id, view }, updateType),
    close: () => set({ reservation: undefined, view: undefined }),
    reservationId: opts.reservation,
    view: (opts.view || 'overview') as View,
    setView: (view: View) => set({ view }),
  };
}

function useReservationQuery(id: Maybe<string>) {
  const [{ id: teamId, partnerId }] = useTeam();
  const [dashboard] = useDashboard();

  const { GL: isGLEnabled } = useEnabledFeatures();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .reservations({
            where: {
              id: { _eq: args.id },
            },
          })
          .map((reservation) => {
            const financials = queryReservationFinancials(reservation, {
              partnerId: args.partnerId,
              tenantId: args.teamId,
              GL: args.isGLEnabled,
            });

            const exluded = financials.filter((x) => x.isInvoice !== 'invoice');
            const included = financials.filter(
              (x) => x.isInvoice === 'invoice'
            );

            const actionTimeline =
              args.dashboard === 'owner'
                ? []
                : reservation
                    ?.actionLinks({
                      order_by: [{ createdAt: 'desc' }],
                    })
                    .map<TimelineItem>((link) => {
                      const action = link.action;

                      const getIcon = () => {
                        const automation =
                          action?.automation || action?.jobPlan?.automation;
                        const left = {
                          id: automation?.leftConnection?.appId,
                          icon: automation?.leftConnection?.app?.iconRound,
                        };
                        const right = {
                          id: automation?.rightConnection?.appId,
                          icon: automation?.rightConnection?.app?.iconRound,
                        };

                        const apps = [left, right].filter(
                          (x) => x?.id !== 'finalytic'
                        );

                        return apps[0]?.icon || null;
                      };

                      const label = [
                        toTitleCase(action.schema?.uniqueRef),
                        action.title,
                      ]
                        .filter(hasValue)
                        .join(' - ');

                      return {
                        id: link.actionId,
                        label,
                        date: action.createdAt,
                        icon: getIcon(),
                      };
                    });
            const changeTimeline =
              args.dashboard === 'owner'
                ? []
                : reservation
                    .changeSourceLinks({
                      order_by: [{ created_at: 'desc' }],
                    })
                    .map<TimelineItem>((sourceChange) => {
                      const getIcon = () => {
                        const automation = sourceChange?.change.automation;

                        const left = {
                          id: automation?.leftConnection?.appId,
                          icon: automation?.leftConnection?.app?.iconRound,
                        };
                        const right = {
                          id: automation?.rightConnection?.appId,
                          icon: automation?.rightConnection?.app?.iconRound,
                        };

                        const apps = [left, right].filter(
                          (x) => x?.id !== 'finalytic'
                        );

                        return apps[0]?.icon || null;
                      };

                      return {
                        id: sourceChange.change.id,
                        label: [
                          toTitleCase(
                            sourceChange.change.entityUniqueRef
                              ?.split('/')
                              .at(0)
                          ),
                          sourceChange.change.message,
                        ]
                          .filter(hasValue)
                          .join(' - '),
                        date: sourceChange.created_at,
                        icon: getIcon(),
                      };
                    });
            const timeline = [...actionTimeline, ...changeTimeline];

            const paymentLines = queryReservationPaymentLines(reservation, {
              includeAllType2s: true,
            });

            const reservationlistingConnectionListing = {
              id: reservation.listingConnection?.listing?.id,
              name: getListingName(reservation.listingConnection?.listing),
            };

            const reservationListing = {
              id: reservation.listingId,
              name: getListingName(reservation.listing),
            };

            const transactionLines = args.isGLEnabled
              ? reservation
                  .transactionLines({
                    order_by: [
                      {
                        updatedAt: 'desc_nulls_last',
                      },
                    ],
                    where: {
                      tenantId: { _eq: args.teamId },
                    },
                  })
                  .map((line) => {
                    return {
                      id: line.id,
                      type: getTransactionLineType(line),
                      centTotal: line.centTotal,
                      transaction: {
                        id: line.transactionId,
                        type: line?.transaction?.type,
                      },
                      description: line.description,
                      party: line.party,
                    };
                  })
              : [];

            return {
              id: reservation.id,
              bookedAt: reservation.bookedAt,
              connection: {
                id: reservation.connection?.id,
                app: {
                  id: reservation.connection?.app?.id,
                  iconRound: reservation.connection?.app?.iconRound,
                  name: reservation.connection?.app?.name,
                },
              },
              channel:
                reservation.channel?.uniqueRef || reservation.bookingPlatform,
              cancelledAt: reservation.cancelledAt,
              checkIn: reservation.checkIn,
              checkOut: reservation.checkOut,
              guestName: reservation.guestName,
              status: reservation.status,
              centTotal: reservation.centTotal,
              centPaid: reservation.centPaid ?? 0,
              paidStatus: reservation.paidStatus,
              userData: reservation.userdata(),
              confirmationCode: reservation.confirmationCode,
              pmsReferenceCode: reservation.pmsReferenceCode,
              deposits: transactionLines.filter(
                (x) => x.transaction.type === 'deposit'
              ),
              expenses: transactionLines.filter(
                (x) => x.transaction.type === 'expense'
              ),
              hasListingOwnership: !!reservation.listing
                ?.ownershipPeriods({
                  limit: 1,
                })
                .map((x) => x.id)[0],
              listing: reservationListing?.id
                ? reservationListing
                : reservationlistingConnectionListing,
              currency: reservation.currency || 'usd',
              journalEntries: args.isGLEnabled
                ? reservation
                    .journalEntries({
                      where: {
                        status: {
                          _neq: 'archived',
                        },
                      },
                      order_by: generalLedgerSorting,
                    })
                    .map((je) => ({
                      id: je.id,
                      txnAt: je.txnAt,
                      txnNum: je.txnNum,
                      description: je.description!,
                      centTotal: je.centTotal!,
                      currency: je.currency,
                      account: je.account?.title,
                    }))
                : undefined,
              financials: {
                included,
                exluded,
              },
              timeline,
              paymentLines: paymentLines.filter((x) => x.isResevationPayment),
              resolutionLines: paymentLines.filter(
                (x) => !x.isResevationPayment
              ),
              files: reservation
                ?.files({
                  order_by: [{ startDate: 'desc_nulls_last' }],
                })
                .map((file) => ({
                  id: file.id,
                  type: file.type,
                  filename: file.filename,
                  ownerName: file.owner && formatUserName(file.owner),
                  startDate: file.startDate,
                  endDate: file.endDate,
                })),
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'reservations',
      variables: {
        id,
        teamId,
        partnerId,
        dashboard,
        isGLEnabled,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type Reservation = NonNullable<ReturnType<typeof useReservationQuery>['data']>;

export const ReservationDetailDrawer = () => {
  const { opened, close, reservationId, view, setView } =
    useReservationDetailDrawer();

  const { colorScheme } = useMantineColorScheme();

  const { colors } = useMantineTheme();
  const [{ id: teamId }] = useTeam();
  const { isLoading, data: reservation } = useReservationQuery(reservationId);

  const {
    hasAutomations,
    automations,
    handlers: automationModalHandlers,
    opened: openedAutomationModal,
    postAutomations,
  } = useAutomationModal('finalytic.reservation');

  const updatePaymentData = useMutation(
    (
      q,
      { reservationId, userdata }: { reservationId: string; userdata: any }
    ) => {
      return q.updateReservation({
        pk_columns: {
          id: reservationId,
        },
        _set: {
          userdata,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['reservations'],
    }
  );

  const hasUserDataEditAccess = [
    '5599d7c0-a692-423c-9e06-3fac8d63048d',
  ].includes(teamId);

  return (
    <>
      <SelectPostAutomationModal
        opened={openedAutomationModal && !!reservationId}
        onClose={automationModalHandlers.close}
        onAutomationSubmit={(automationIds) =>
          postAutomations({
            allPagesSelected: false,
            automationIds,
            filterState: {},
            getSelectedRowIds: () => (reservationId ? [reservationId] : []),
            where: {},
            selectedItemType: 'finalytic.reservation',
            skipCachedActions: false,
            skipSelectionNeccesary: false,
          }).then(close)
        }
        type="finalytic.reservation"
        automations={automations}
      />
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          title={
            reservation && (
              <>
                <Group mt={rem(5)} wrap="nowrap">
                  <Avatar src={reservation.connection.app.iconRound} />
                  <Box>
                    <Text size="lg" fw={500}>
                      {reservation.guestName}
                    </Text>
                    <Text
                      size="xs"
                      c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
                      fw={400}
                    >
                      {`${uniqueBy(
                        [
                          reservation?.confirmationCode,
                          reservation?.pmsReferenceCode,
                        ].filter((x) => x)
                      ).join(', ')}`}
                    </Text>
                  </Box>
                </Group>
              </>
            )
          }
          type="Reservation"
          loading={isLoading}
          menuItems={
            view === 'overview' &&
            (hasUserDataEditAccess || hasAutomations) &&
            reservation && (
              <>
                {hasAutomations && (
                  <EllipsisMenuItem
                    onClick={automationModalHandlers.open}
                    customIcon={<RocketIcon size={16} />}
                  >
                    Post Automation
                  </EllipsisMenuItem>
                )}
                {hasUserDataEditAccess && (
                  <EllipsisMenuItem
                    onClick={() => setView('edit')}
                    customIcon={<Edit3Icon size={16} />}
                  >
                    Edit User Data
                  </EllipsisMenuItem>
                )}
              </>
            )
          }
        />
        {!reservation && !isLoading ? (
          'No reservation found'
        ) : view === 'edit' && reservation ? (
          <ReservationEditForm
            initialValues={{
              userData: JSON.stringify(reservation.userData || {}, null, 2),
            }}
            onReset={() => setView('overview')}
            isLoading={isLoading}
            handleSubmit={async (values) => {
              updatePaymentData
                .mutate({
                  args: {
                    reservationId: reservation.id,
                    userdata: JSON.parse(values.userData?.trim() || '{}'),
                  },
                })
                .then(() => setView('overview'));
            }}
          />
        ) : (
          <ReservationDetail reservation={reservation} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const ReservationDetail = ({
  reservation,
  isLoading,
}: { reservation: Maybe<Reservation>; isLoading: boolean }) => {
  const { GL } = useEnabledFeatures();
  const tabs = useMemo(() => {
    const tabs: (
      | 'financials'
      | 'excluded'
      | 'payments'
      | 'paymentFeesAndReserves'
      | 'journalEntries'
    )[] = [];

    if (!reservation) return [];

    const limit = 10;

    if (
      !GL &&
      Object.values(
        groupBy(reservation.paymentLines, (x) => x.paymentId || '')
      ).some((x) => x.length > limit)
    )
      tabs.push('payments');

    if (
      Object.values(
        groupBy(reservation.resolutionLines, (x) => x.paymentId || '')
      ).some((x) => x.length > limit)
    )
      tabs.push('paymentFeesAndReserves');

    if (reservation.financials.included.length > limit) tabs.push('financials');
    if (GL && (reservation.journalEntries?.length || 0) > limit)
      tabs.push('journalEntries');
    if (reservation.financials.exluded.length > limit) tabs.push('excluded');

    return tabs;
  }, [reservation, GL]);

  if (!reservation) return null;

  // 2 financials not grouped
  // payments/resolutions grouped

  const showTabs = !!tabs.length;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
        flex: 1,
      }}
    >
      {!showTabs ? (
        <Overview reservation={reservation} />
      ) : (
        <>
          <Tabs
            defaultValue="overview"
            styles={(theme) => ({
              root: {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                flex: 1,
              },
              panel: {
                height: '100%',
                flex: 1,
                marginTop: theme.spacing.md,
                maxWidth: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                maxHeight: 'calc(100vh - 140px)',
              },
            })}
          >
            <Tabs.List>
              <Tabs.Tab value="overview">Overview</Tabs.Tab>
              {tabs.map((key) => {
                return (
                  <Tabs.Tab key={key} value={key}>
                    {toTitleCase(key)}
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>

            <Tabs.Panel value="overview">
              <Stack gap={'md'} pb="md">
                <Alert icon={<Icon icon="AlertCircleIcon" size={20} />}>
                  <Text size="sm">
                    This reservation contains more data than usual. You can use
                    tabs to view the data more easily.
                  </Text>
                </Alert>
                <Overview reservation={reservation} />
              </Stack>
            </Tabs.Panel>
            {tabs.map((key) => {
              return (
                <Tabs.Panel key={key} value={key}>
                  {key === 'payments' && (
                    <Payments
                      currency={reservation.currency}
                      rowData={reservation.paymentLines}
                      title="Payments"
                      type="tab"
                    />
                  )}
                  {key === 'paymentFeesAndReserves' && (
                    <Payments
                      currency={reservation.currency}
                      rowData={reservation.resolutionLines}
                      title="Payments"
                      type="tab"
                    />
                  )}

                  {key === 'financials' && (
                    <Financials
                      currency={reservation.currency}
                      rowData={reservation.financials.included}
                      reservationId={reservation.id}
                      title="Payments"
                      type="tab"
                    />
                  )}
                  {key === 'journalEntries' && (
                    <JournalEntries
                      title="Journal Entries"
                      currency={reservation.currency}
                      rowData={reservation.journalEntries || []}
                      hasListingOwnership={reservation.hasListingOwnership}
                      reservationId={reservation.id}
                      type="tab"
                    />
                  )}

                  {key === 'excluded' && (
                    <Financials
                      currency={reservation.currency}
                      rowData={reservation.financials.exluded}
                      reservationId={reservation.id}
                      title="Payments"
                      type="tab"
                      hideAddFinancial
                    />
                  )}
                </Tabs.Panel>
              );
            })}
          </Tabs>
        </>
      )}
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

const Overview = ({ reservation }: { reservation: Reservation }) => {
  const setListing = useQueryParamSet('listing', StringParam);
  const setView = useQueryParamSet('view', StringParam);

  const { GL } = useEnabledFeatures();
  const userDataKeys = Object.keys(reservation.userData || {});

  return (
    <>
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: <ReservationStatusBadge status={reservation.status} />,
          },

          {
            icon: LoaderIcon,
            title: 'Payment',
            text: GL ? (
              <ReservationPaymentStatusBadge
                paidStatus={reservation.paidStatus}
              />
            ) : null,
          },

          {
            icon: HomeIcon,
            title: 'Listing',
            text: (
              <Anchor
                onClick={() => setListing(reservation.listing.id, 'push')}
              >
                {reservation.listing.name}
              </Anchor>
            ),
          },
          {
            icon: CalendarEventIcon,
            title: 'Booked',
            text: `${
              reservation.bookedAt
                ? utc(reservation.bookedAt).format('MMM DD, YYYY')
                : 'unknown date'
            }, ${toTitleCase(reservation.channel || 'unknown channel')}`,
          },
          {
            icon: CrossCircleIcon,
            title: 'Cancelled At',
            text:
              reservation.status === 'cancelled' && reservation.cancelledAt
                ? utc(reservation.cancelledAt).format('MMM DD, YYYY')
                : undefined,
          },
          {
            icon: CalendarDatesIcon,
            title: 'Dates',
            text: [reservation.checkIn, reservation.checkOut]
              .filter(hasValue)
              .map((x) => utc(x).format('MMM DD, YYYY'))
              .join(' - '),
          },
          {
            icon: UserIcon,
            title: 'User data',
            text: !!userDataKeys.length && (
              <Anchor onClick={() => setView('edit')}>
                {userDataKeys.length} updated key
                {userDataKeys.length > 1 && 's'}
              </Anchor>
            ),
          },
        ]}
      />

      <PaymentProgress
        currency={reservation.currency}
        financials={reservation.financials.included}
        paymentLines={reservation.paymentLines}
        centPaid={reservation.centPaid}
      />

      {!GL && (
        <Payments
          currency={reservation.currency}
          rowData={reservation.paymentLines}
          title="Payments"
        />
      )}

      {!!reservation.resolutionLines.length && (
        <Payments
          currency={reservation.currency}
          rowData={reservation.resolutionLines}
          title="Payment Fees & Reserves"
        />
      )}

      <Financials
        title="Financials"
        currency={reservation.currency}
        rowData={reservation.financials.included}
        reservationId={reservation.id}
      />

      {!!reservation.financials.exluded.length && (
        <Financials
          title="Financials - Excluded items"
          currency={reservation.currency}
          rowData={reservation.financials.exluded}
          reservationId={reservation.id}
          hideAddFinancial
        />
      )}

      {GL && (
        <>
          <Transactions
            rowData={reservation.deposits}
            currency={reservation.currency}
            type="deposits"
          />
          <Transactions
            rowData={reservation.expenses}
            currency={reservation.currency}
            type="expenses"
          />
        </>
      )}

      {reservation.journalEntries && GL ? (
        <JournalEntries
          title="Journal Entries"
          currency={reservation.currency}
          rowData={reservation.journalEntries}
          hasListingOwnership={!!reservation.hasListingOwnership}
          reservationId={reservation.id}
        />
      ) : null}

      {GL && (
        <Payments
          currency={reservation.currency}
          rowData={reservation.paymentLines}
          title="Payments"
        />
      )}

      {!GL && <ReservationTimeline data={reservation.timeline} />}

      {!!reservation.files.length && <Files rowData={reservation.files} />}
    </>
  );
};

const JournalEntriesPlaceholder = ({
  hasListingOwnership,
  reservationId,
}: { hasListingOwnership: boolean; reservationId: string }) => {
  const invalidate = useInvalidateQueries(['reservations', 'generalLedger']);

  const onSubmit = async () => {
    try {
      await mutateAsync({
        requestBody: {
          ids: [reservationId],
        },
      });
    } catch (err: any) {
      const error = err as TrpcApiError;
      const message =
        error?.body?.message ||
        'Please reach out to support if the issue persists.';

      if (error.status !== 400) captureSentryError(message);

      showErrorNotification({
        title: 'Failed to refresh the general ledger',
        message,
      });
    }
  };

  const { mutateAsync, isLoading: loading } =
    useReservationsServiceCreateReservationJournalEntries({
      onSettled: () => {
        invalidate();
      },
      onSuccess: () => {
        showSuccessNotification({
          title: 'Success!',
          message: 'Journal entries were successfully refreshed.',
        });
      },
    });

  if (!hasListingOwnership)
    return (
      <Alert
        variant="light"
        color="orange"
        title={
          <Group gap="xs">
            <Icon
              icon="HomeIcon"
              size={18}
              color={(theme) => theme.colors.orange[6]}
            />
            No ownership found
          </Group>
        }
      >
        Please add an owner to the listing to generate journal entries for this
        reservation.
      </Alert>
    );

  return (
    <Center
      px="lg"
      sx={{
        flexDirection: 'column',
      }}
    >
      <Icon
        icon="AlertTriangleIcon"
        size={24}
        color={(theme) => theme.colors.orange[6]}
      />
      <Text size="sm" c="gray" ta="center" mt="sm" mb="sm">
        Please refresh the journal entries for this reservation.
      </Text>
      <Button leftIcon="RefreshCwIcon" loading={loading} onClick={onSubmit}>
        Refresh
      </Button>
    </Center>
  );
};

const JournalEntries = ({
  rowData = [],
  currency,
  title,
  type = 'collapse',
  ...placeholderProps
}: {
  rowData: Reservation['journalEntries'];
  currency: string;
  title: string;
  type?: 'tab' | 'collapse';
} & ComponentProps<typeof JournalEntriesPlaceholder>) => {
  const [search, setSearch] = useState('');

  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  const columns = useMemo<
    MRT_ColumnDef<NonNullable<Reservation['journalEntries']>[0]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <Box>
              <Text size="sm">{data.description}</Text>
              <Text size="xs" color="gray">
                {data.account}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'txnNum',
        accessorKey: 'txnNum',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const txnNum = row.original.txnNum;

          if (!txnNum) return null;

          return (
            <Text size="xs" c="gray" ta="right">
              {txnNum}
            </Text>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'amount',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            currency
          );
          return (
            <Box>
              <Text size="sm" ta="right">
                {amount}
              </Text>
              {row.original.txnAt && (
                <Text size="xs" c="gray" ta="right">
                  {day(row.original.txnAt).format('MMM DD, YYYY')}
                </Text>
              )}
            </Box>
          );
        },
      },
    ],
    [currency]
  );

  const trimmed = search.trim();

  const filtered = useMemo(() => {
    if (!trimmed) return rowData;

    return rowData.filter((row) => {
      const matchDes = row.description
        ?.toLowerCase()
        .includes(trimmed.toLowerCase());
      const matchAccount = row.account
        ?.toLowerCase()
        .includes(trimmed.toLowerCase());

      return matchDes || matchAccount;
    });
  }, [trimmed, rowData]);

  if (type === 'tab') {
    return (
      <LazyTable
        data={{
          loading: false,
          error: false,
          rowCount: filtered.length,
          rows: filtered,
        }}
        table={{
          columns,
          // hideTopBar: true,
          hideSettings: true,
          hideHeader: true,
          hidePagination: true,
          emptyRowsFallback: () => (
            <JournalEntriesPlaceholder {...placeholderProps} />
          ),
        }}
        resetFilter={() => setSearch('')}
      >
        <Filter.Search setValue={setSearch} value={search} />
      </LazyTable>
    );
  }

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={rowData}
      columns={columns}
      defaultOpened
      emptyRowsFallback={() => (
        <JournalEntriesPlaceholder {...placeholderProps} />
      )}
    />
  );
};

const Financials = ({
  rowData,
  currency,
  title,
  type = 'collapse',
  reservationId,
  hideAddFinancial,
}: {
  rowData: Reservation['financials']['included'];
  currency: string;
  title: string;
  reservationId: string;
  type?: 'tab' | 'collapse';
  hideAddFinancial?: boolean;
}) => {
  const [search, setSearch] = useState('');

  const [{ partnerId }] = useTeam();
  const { isSuperAdmin } = useTeamRole();
  const { GL } = useEnabledFeatures();

  const [opened, setOpened] = useState<{
    reservationId: string;
    lineType: string | null;
    description: string;
    centTotal: number | undefined;
    party: party_enum;
  } | null>(null);

  const [deleteModalReservationLine, setDeleteModalReservationLine] = useState<{
    id: string;
    reservationId: string;
  } | null>(null);

  const { mutate: upsertSettingMutation, loading: loadingSettingUpsert } =
    useMutation(
      (
        q,
        args: {
          settingId: Maybe<string>;
          type2: string;
          tenantId: string;
          value: 'excluded' | 'invoice';
          isLocalAutomationSetting: boolean;
        }
      ) => {
        if (args.isLocalAutomationSetting && args.settingId) {
          return q.insert_setting_one({
            object: {
              id: args.settingId,
              tenant_id: args.tenantId,
              value: args.value === 'invoice' ? 'invoice' : 'exclude',
              group: 'ximplifi',
              key: 'inclusion',
              target: args.type2,
              lineClassification: args.type2,
              leftType: 'finalytic.lineType',
            },
            on_conflict: {
              constraint: 'setting_pkey',
              update_columns: ['value'],
            },
          })?.id;
        }

        return q.insert_setting_one({
          object: {
            id: args.settingId,
            key: 'accountingType',
            group: 'ximplifi',
            partner: 'ximplifi',
            leftType: 'finalytic.lineType',
            tenant_id: args.tenantId,
            value: args.value,
            target: args.type2,
            lineClassification: args.type2,
          },
          on_conflict: {
            constraint: 'setting_pkey',
            update_columns: ['value'],
          },
        })?.id;
      },
      {
        invalidateQueryKeys: ['reservations'],
      }
    );

  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  const columns = useMemo<
    MRT_ColumnDef<Reservation['financials']['included'][0]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const data = row.original;

          if (GL) {
            const type = data.type2?.split('_').reverse()[0];

            if (!type || data.connectionId) return data.description;

            return (
              <Box>
                <Text>{data.description}</Text>
                <Text c="gray" size="xs">
                  {toTitleCase(type)}
                </Text>
              </Box>
            );
          }

          if (isSuperAdmin && !GL)
            return (
              <Group gap={'xs'} wrap="nowrap">
                <Tooltip
                  label={
                    <>
                      {data.type2}
                      {data?.lineItemSetting &&
                        (data?.lineItemSetting?.key === 'inclusion' ? (
                          <>
                            <br />
                            (team setting)
                          </>
                        ) : (
                          <>
                            <br />
                            (partner setting)
                          </>
                        ))}
                    </>
                  }
                  withArrow
                  withinPortal
                >
                  <Center>
                    <InfoIcon size={16} />
                  </Center>
                </Tooltip>
                <Box>
                  <Text size="sm">{data.description}</Text>
                </Box>
              </Group>
            );

          return data.description;
        },
      },
      {
        header: 'amount',
        accessorKey: 'amount',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            currency
          );

          if (!GL) return amount;

          return (
            <Group wrap="nowrap" gap="xs" justify="flex-end">
              <Text>{amount}</Text>
              {rowData.some((x) => !x.connectionId) &&
                (!row.original.connectionId ? (
                  <IconButton
                    icon="TrashIcon"
                    size={16}
                    onClick={() =>
                      setDeleteModalReservationLine({
                        id: row.original.id,
                        reservationId,
                      })
                    }
                    tooltip="Remove adjustment"
                  />
                ) : (
                  <Box w={28} />
                ))}
            </Group>
          );
        },
      },
    ],
    [currency, isSuperAdmin, GL, rowData, reservationId]
  );

  const trimmed = search.trim();

  const filtered = useMemo(() => {
    if (!trimmed) return rowData;

    return rowData.filter((row) => {
      return row.description?.toLowerCase().includes(trimmed.toLowerCase());
    });
  }, [trimmed, rowData]);

  if (type === 'tab') {
    return (
      <LazyTable
        data={{
          loading: false,
          error: false,
          rowCount: filtered.length,
          rows: filtered,
        }}
        table={{
          columns,
          // hideTopBar: true,
          hideSettings: true,
          hideHeader: true,
          hidePagination: true,
          emptyRowsFallback: () => (
            <Center>
              <Text size="sm" c="gray">
                No financials available
              </Text>
            </Center>
          ),
        }}
        rowMenu={
          GL || !isSuperAdmin
            ? undefined
            : {
                menuItems: ({ row }) => {
                  const isXimplifiPartner = partnerId === XIMPLIFI_TENANT_ID;

                  if (!row.original.type2 || !isXimplifiPartner) return null;

                  const isExcluded = row.original.isInvoice === 'excluded';

                  const lineItemSetting = row.original.lineItemSetting;

                  const isLocalAutomationSetting =
                    lineItemSetting?.key === 'inclusion';

                  return (
                    <>
                      <HiddenFeatureIndicator permission="super-admin">
                        <EllipsisMenuItem
                          customIcon={<CrossIcon size={16} />}
                          loading={loadingSettingUpsert}
                          onClick={() => {
                            upsertSettingMutation({
                              args: {
                                settingId: lineItemSetting?.id,
                                type2: row.original.type2!,
                                tenantId: partnerId,
                                value: isExcluded ? 'invoice' : 'excluded',
                                isLocalAutomationSetting,
                              },
                            });
                          }}
                        >
                          {isExcluded ? 'Include' : 'Exclude'} item
                        </EllipsisMenuItem>
                      </HiddenFeatureIndicator>
                    </>
                  );
                },
              }
        }
        resetFilter={() => setSearch('')}
      >
        <Filter.Search setValue={setSearch} value={search} />
      </LazyTable>
    );
  }

  return (
    <Box>
      <DrawerCollapsableTable
        title={title}
        rightSection={
          <Text size="sm" fw={500}>
            {formatCurrency(total, currency)}
          </Text>
        }
        rowMenu={
          GL || !isSuperAdmin
            ? undefined
            : {
                menuItems: ({ row }) => {
                  const isXimplifiPartner = partnerId === XIMPLIFI_TENANT_ID;

                  if (!row.original.type2 || !isXimplifiPartner) return null;

                  const isExcluded = row.original.isInvoice === 'excluded';

                  const lineItemSetting = row.original.lineItemSetting;

                  const isLocalAutomationSetting =
                    lineItemSetting?.key === 'inclusion';

                  return (
                    <>
                      <HiddenFeatureIndicator permission="super-admin">
                        <EllipsisMenuItem
                          customIcon={<CrossIcon size={16} />}
                          loading={loadingSettingUpsert}
                          onClick={() => {
                            upsertSettingMutation({
                              args: {
                                settingId: lineItemSetting?.id,
                                type2: row.original.type2!,
                                tenantId: partnerId,
                                value: isExcluded ? 'invoice' : 'excluded',
                                isLocalAutomationSetting,
                              },
                            });
                          }}
                        >
                          {isExcluded ? 'Include' : 'Exclude'} item
                        </EllipsisMenuItem>
                      </HiddenFeatureIndicator>
                    </>
                  );
                },
              }
        }
        rowData={rowData}
        columns={columns}
        emptyRowsFallback={() => (
          <Center>
            <Text size="sm" c="gray">
              No financials available
            </Text>
          </Center>
        )}
      />
      {GL && !hideAddFinancial && (
        <>
          <Center>
            <Button
              variant="light"
              leftIcon={'PlusIcon'}
              onClick={() =>
                setOpened({
                  centTotal: undefined,
                  description: '',
                  lineType: null,
                  reservationId,
                  party: 'manager',
                })
              }
            >
              Add adjustment
            </Button>
          </Center>
          <ReservationLineFormModal
            closeModal={() => setOpened(null)}
            reservationId={opened?.reservationId || null}
            reservationLine={opened}
          />
          <ReservationLineDeleteModal
            closeModal={() => setDeleteModalReservationLine(null)}
            reservationLine={deleteModalReservationLine}
          />
        </>
      )}
    </Box>
  );
};

const Transactions = ({
  rowData,
  currency,
  type,
}: {
  rowData: Reservation['deposits'];
  currency: string;
  type: 'expenses' | 'deposits';
}) => {
  const { GL } = useEnabledFeatures();

  const { open: openDeposit } = useDepositDetailDrawer();
  const { open: openExpense } = useExpenseDetailDrawer();
  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  const columns = useMemo<MRT_ColumnDef<Reservation['deposits'][0]>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const description = row.original.description;

          if (row.original.transaction.type === 'expense') return description;

          if (!row.original.type) return description || 'Reservation Payment';

          return description;
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
        Cell: ({ row }) => {
          if (row.original.transaction.type === 'expense') return null;

          return (
            <Badge color={row.original.type === 'refund' ? 'blue' : 'gray'}>
              {toTitleCase(row.original.type || 'Payment')}
            </Badge>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'amount',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            currency
          );

          const party = row.original.party;

          return (
            <Group wrap="nowrap" gap="xs" justify="flex-end">
              {party && (
                <Tooltip label={toTitleCase(party)} withArrow>
                  <Icon
                    icon={party === 'owners' ? 'UsersIcon' : 'DashboardIcon'}
                    size={14}
                  />
                </Tooltip>
              )}
              <Text>{amount}</Text>
            </Group>
          );
        },
      },
    ],
    [currency]
  );

  if (!GL) return null;

  return (
    <DrawerCollapsableTable
      title={toTitleCase(type)}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      onRowClick={{
        handler: (row) =>
          type === 'expenses'
            ? openExpense(row.original.transaction.id, 'push')
            : openDeposit(row.original.transaction.id, 'push'),
        disabled: (row) => !row.original.transaction.id,
      }}
      rowData={rowData}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No financials available
          </Text>
        </Center>
      )}
    />
  );
};

const Files = ({
  rowData,
}: {
  rowData: Reservation['files'];
}) => {
  const { download } = useFileDownload();

  const [dashboard] = useDashboard();

  return (
    <DrawerCollapsableTable
      title={'Files'}
      rightSection={null}
      rowData={rowData}
      columns={[
        {
          header: 'Name',
          accessorKey: 'filename',
        },
        dashboard !== 'owner'
          ? {
              header: 'Owner',
              accessorKey: 'ownerName',
            }
          : undefined,
        {
          header: 'startDate',
          accessorKey: 'startDate',
        },
      ].filter(hasValue)}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No files found
          </Text>
        </Center>
      )}
      onRowClick={{
        handler: (row) => {
          download([row.original.id]);
        },
      }}
    />
  );
};

const PaymentProgress = ({
  financials,
  paymentLines,
  currency,
  centPaid,
}: {
  paymentLines: Reservation['paymentLines'];
  financials: Reservation['financials']['included'];
  centPaid: number;
  currency: string;
}) => {
  const { GL } = useEnabledFeatures();

  const totalFinancials = sum(financials, (x) => x.centTotal || 0);
  const totalPayments = GL
    ? centPaid
    : sum(paymentLines, (x) => x.centTotal || 0);
  const totalPending = totalFinancials - totalPayments;

  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Card
      sx={(theme) => ({
        backgroundColor:
          colorScheme === 'dark' ? undefined : theme.colors.neutral[1],
        display: 'flex',
        flexDirection: 'column',
        gap: rem(14),
      })}
      radius="md"
      mb="sm"
    >
      <Group justify="space-between">
        <Amount
          align="left"
          centTotal={totalPayments}
          currency={currency}
          label="Paid"
        />

        {totalPending ? (
          <Amount
            align="center"
            centTotal={totalPending}
            currency={currency}
            label="Pending"
            isPending
          />
        ) : (
          <Center sx={{ flexDirection: 'column' }}>
            <CheckCircleIcon size={24} color={colors.green[7]} mb={8} />
            <Badge color="green">All Paid</Badge>
          </Center>
        )}

        <Amount
          align="right"
          centTotal={totalFinancials}
          currency={currency}
          label="Expected"
        />
      </Group>

      <Progress
        value={(totalPayments / totalFinancials) * 100}
        color={!totalPending ? 'green' : undefined}
      />
    </Card>
  );
};

const Amount = ({
  align,
  centTotal,
  currency,
  isPending,
  label,
}: {
  align: 'left' | 'center' | 'right';
  centTotal: number;
  currency: string;
  label: string;
  isPending?: boolean;
}) => {
  const amount = formatCurrency(centTotal / 100, currency);
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Box>
      <Text
        ta={align}
        size="sm"
        c={colorScheme === 'dark' ? undefined : isPending ? 'gray' : 'neutral'}
      >
        {label}
      </Text>
      <Text
        ta={align}
        c={
          isPending
            ? colorScheme === 'dark'
              ? colors.gray[6]
              : 'gray'
            : undefined
        }
        fw={500}
        size="lg"
      >
        {amount}
      </Text>
    </Box>
  );
};

const Payments = ({
  rowData,
  currency,
  title,
  type = 'collapse',
}: {
  rowData: Reservation['paymentLines'];
  currency: string;
  title: string;
  type?: 'tab' | 'collapse';
}) => {
  const [search, setSearch] = useState('');

  const setPayment = useQueryParamSet('payment', StringParam);

  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  type PaymentRow = {
    id: string;
    description: string;
    type: Maybe<Reservation['paymentLines'][0]['type']>;
    centTotal: number;
    isParent: boolean;
    lines: PaymentRow[];
  };

  const groupedRowData = useMemo(
    () =>
      Object.entries(groupBy(rowData, (x) => x.paymentId)).map<PaymentRow>(
        ([paymentId, paymentLines]) => {
          const firstPaymentLine = paymentLines[0];
          const payedAt = firstPaymentLine?.payment?.payedAt || '';

          const description =
            firstPaymentLine?.payment?.description || 'Payment';

          return {
            id: paymentId,
            isParent: true,
            type: firstPaymentLine?.type,
            centTotal: sum(paymentLines, (x) => x.centTotal || 0),
            description: [
              toTitleCase(description),
              payedAt ? `(${utc(payedAt).format('DD MMM YYYY')})` : '',
            ]
              .filter((i) => !!i)
              .join(' '),
            lines: paymentLines.map((paymentLine) => ({
              centTotal: paymentLine.centTotal || 0,
              description: paymentLine.description || '',
              lines: [],
              type: null,
              isParent: false,
              id: paymentLine.id,
            })),
          };
        }
      ),
    [rowData]
  );

  const trimmed = search.trim();

  const filtered = useMemo(() => {
    if (!trimmed) return groupedRowData;

    return groupedRowData.filter((row) => {
      const line = row.description
        ?.toLowerCase()
        .includes(trimmed.toLowerCase());
      const childs = row.lines.some((x) =>
        x.description?.toLowerCase().includes(trimmed.toLowerCase())
      );

      return line || childs;
    });
  }, [trimmed, groupedRowData]);

  const columns = useMemo<MRT_ColumnDef<PaymentRow>[]>(
    () => [
      {
        header: 'Payed On',
        accessorKey: 'description',
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return formatCurrency((row.original.centTotal || 0) / 100, currency);
        },
      },
    ],
    [currency]
  );

  if (type === 'tab') {
    return (
      <LazyTable
        data={{
          loading: false,
          error: false,
          rowCount: filtered.length,
          rows: filtered,
        }}
        table={{
          columns,
          hideSettings: true,
          hideHeader: true,
          hidePagination: true,
          onRowClick: {
            handler: (row) => {
              if (!row.original.isParent) return;
              setPayment(row.original.id, 'push');
            },
            disabled: (row) => !row.original.isParent,
          },
          emptyRowsFallback: () => (
            <Center>
              <Text size="sm" color="gray">
                No payments available
              </Text>
            </Center>
          ),
        }}
        subRows={{
          getRowCanExpand: (row) => !!row.original.lines.length,
          defaultExpanded: true,
          getSubRows: (row) => row.lines,
        }}
        resetFilter={() => setSearch('')}
      >
        <Filter.Search setValue={setSearch} value={search} />
      </LazyTable>
    );
  }

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={groupedRowData}
      columns={columns}
      subRows={{
        getRowCanExpand: (row) => !!row.original.lines.length,
        defaultExpanded: true,
        getSubRows: (row) => row.lines,
      }}
      onRowClick={{
        handler: (row) => {
          if (!row.original.isParent) return;
          setPayment(row.original.id, 'push');
        },
        disabled: (row) => !row.original.isParent,
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No payments available
          </Text>
        </Center>
      )}
    />
  );
};

const ReservationTimeline = ({ data }: { data: Reservation['timeline'] }) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Box>
      <Collapse
        title={
          <Text component="span" size="sm">
            Timeline
            <Text
              color={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
              ml="xs"
              size="xs"
              component="span"
            >
              {data.length}
            </Text>
          </Text>
        }
        minHeight={30}
        defaultOpened={!!data.length}
      >
        <Timeline data={data} order="desc" />
      </Collapse>
    </Box>
  );
};
