import { useInfiniteQuery, useTeamId } from '@finalytic/data';
import type {
  Query,
  accountClassification_enum,
  journalEntryEntityType_enum,
  journalEntry_bool_exp,
  journalEntry_order_by,
  party_enum,
} from '@finalytic/graphql';
import { Maybe, formatCurrency, toTitleCase } from '@finalytic/utils';
import {
  formatOwnerName,
  getListingName,
  whereJournalEntries,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useGeneralLedgerDetailFilter } from './GeneralLedgerDetailFilter';

export type GeneralLedgerDetailRow = NonNullable<
  ReturnType<typeof useGeneralLedgerDetailTableQuery>['data']
>['pages'][number]['list'][number];

export const generalLedgerSorting: journalEntry_order_by[] = [
  {
    txnAt: 'desc_nulls_last',
  },
  {
    txnNum: 'asc_nulls_last',
  },
  {
    centTotal: 'desc_nulls_last',
  },
];
export const useWhereGeneralLedgerDetail = () => {
  const [teamId] = useTeamId();

  const { filter } = useGeneralLedgerDetailFilter();

  const search = (filter.search || '').trim();

  return useMemo<journalEntry_bool_exp>(() => {
    return whereJournalEntries({
      date: filter.date,
      tenantId: teamId,
      type: filter.type as Maybe<journalEntryEntityType_enum>,
      search,
      party: filter.party as Maybe<party_enum>,
      listingId: filter.listingId,
      accountClassification: filter.accountClassification as
        | accountClassification_enum
        | undefined
        | null,
      accountId: filter.accountId,
      status: filter.status as 'draft' | 'published' | undefined | null,
    });
  }, [
    teamId,
    search,
    filter.date,
    filter.listingId,
    filter.party,
    filter.type,
    filter.accountId,
    filter.accountClassification,
    filter.status,
  ]);
};

export const getGeneralLedgerDetailRows = (
  q: Query,
  args: {
    where: journalEntry_bool_exp;
    teamId: string;
    limit: number | undefined;
    offset: number | undefined;
  }
) =>
  q
    .journalEntries({
      where: args.where,
      limit: args.limit,
      offset: args.offset,
      order_by: generalLedgerSorting,
    })
    .map((item) => {
      const period = item.listingOwnershipPeriod;

      const centTotal = item.centTotal || 0;
      const currency = item.currency || 'usd';
      const debit =
        centTotal > 0 ? formatCurrency(centTotal / 100, currency) : '';
      const credit =
        centTotal < 0 ? formatCurrency(centTotal / 100, currency) : '';

      const recurringFeeId = item.recurringFeeId;
      const entityType = item.entityType;

      const transaction = {
        id: item.transaction?.id,
        uniqueRef: item.transaction?.uniqueRef,
        type: item.transaction?.type,
        paidStatus: item.transaction?.paidStatus,
      };

      return {
        id: item.id,
        reservation: {
          id: item.reservation?.id,
          checkIn: item.reservation?.checkIn,
          confirmationCode: item.reservation?.confirmationCode,
        },
        transaction,
        ownerStatement: {
          id: item.ownerStatement?.id,
          startAt: item.ownerStatement?.startAt,
          currency: item.ownerStatement?.currency,
          listingOwnershipPeriodId:
            item.ownerStatement?.listingOwnershipPeriodId,
        },
        listingId: period?.listing?.id,
        listingOwnershipPeriodId: period?.id,
        period: {
          id: period?.id,
          startAt: period?.startAt,
          endAt: period?.endAt,
          listing: {
            id: period?.listing?.id,
            name: getListingName(period?.listing, { allowEmpty: true }),
          },
          owners: period
            ?.members({
              order_by: [{ owner: { name: 'asc_nulls_last' } }],
            })
            .map((member) => ({
              id: member.owner.id,
              name: formatOwnerName(member.owner, {
                lastNameFirst: false,
                showEmpty: true,
              }),
              type: member.owner.type || 'individual',
            })),
        },
        party: toTitleCase(item.party),
        currency: item.currency || 'usd',
        entityType: recurringFeeId ? 'recurringFee' : entityType,
        accountId: item.accountId,
        accountCode: item.account?.uniqueRef,
        accountTitle: (() => {
          if (
            transaction.paidStatus === 'paid' &&
            transaction.type === 'expense' &&
            !item.accountId
          )
            return 'Non-Trust Account';

          return item.account?.title;
        })(),
        debit,
        credit,
        txnAt: item.txnAt,
        txnNum: item.txnNum,
        description: item.description,
        uniqueRef: item.uniqueRef,
      };
    });

export const useGeneralLedgerDetailTableQuery = () => {
  const [teamId] = useTeamId();

  const where = useWhereGeneralLedgerDetail();

  return useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      const aggregate =
        q.journalEntryAggregate({ where }).aggregate?.count() || 0;

      const list = getGeneralLedgerDetailRows(q, {
        where,
        teamId,
        limit,
        offset,
      });

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'generalLedger',
      variables: { teamId, where },
    }
  );
};
