import {
  currency_enum,
  paidStatus_enum,
  party_enum,
  taxBehavior_enum,
} from '@finalytic/graphql';
import { useFormContext } from 'react-hook-form';

export type ExpenseFormInputs = {
  id: string | undefined;
  date: string;
  accountId: (string & {}) | 'non-trust';
  currency: currency_enum;
  description: string;
  contactId: string | null;
  uniqueRef: string;
  paidStatus: paidStatus_enum;
  paidAt: string | null;
  lines: {
    id: string | undefined;
    accountId: string | null;
    party: party_enum;
    listingId: string | null;
    reservationId: string | null;
    description: string;
    centTotal: number;
    rateId: string | null;
    rateBehavior: taxBehavior_enum | null;
    markup: number | undefined;
    // markupId: string | null;
    // markupTaxRateId: string | null;
    // markupTaxBehavior: gqlV2.taxBehavior_enum | null;
    // markupType: 'rate' | 'cent';
  }[];
};

export const useExpenseForm = () => useFormContext<ExpenseFormInputs>();
