import { Badge } from '@finalytic/components';
import { useQuery, useTeamId } from '@finalytic/data';
import { paidStatus_enum } from '@finalytic/graphql';
import {
  CalendarEventIcon,
  CircleDollarIcon,
  FolderOpenIcon,
  HashtagIcon,
  Icon,
  LoaderIcon,
  NoteTextIcon,
  UserIcon,
} from '@finalytic/icons';
import { MRT_ColumnDef } from '@finalytic/table';
import { Drawer } from '@finalytic/ui';
import { Maybe, day, formatCurrency, sum, toTitleCase } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Center,
  Group,
  HoverCard,
  LoadingOverlay,
  Stack,
  Title,
  rem,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { getExpense } from '../../views/expenses/list/useExpenseTableQuery';
import { useListingDetailDrawer } from '../../views/listings/drawer';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useReservationDetailDrawer } from '../reservation-drawers';
import { ExpenseEllipsisMenuItems } from './ExpenseEllipsisMenuItems';
import { ExpenseEllipsisMenuModals } from './ExpenseEllipsisMenuModals';
import { useExpenseDetailDrawer } from './useExpenseDetailDrawer';

function useExpenseQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .transactions({
            where: {
              id: { _eq: args.id },
              type: { _eq: 'expense' },
            },
            limit: 1,
          })
          .map((expense) =>
            getExpense(expense, {
              includeJournalEntries: true,
              includeContact: true,
            })
          )[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'expenses',
      variables: {
        id,
        teamId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type Expense = ReturnType<typeof getExpense>;

export const ExpenseDetailDrawer = () => {
  const { opened, close, expenseId } = useExpenseDetailDrawer();
  const { isLoading, data: expense } = useExpenseQuery(expenseId);

  const [openedModal, setOpenedModal] = useState<
    'delete' | 'edit' | 'payment-status' | null
  >(null);

  const closeModal = () => setOpenedModal(null);

  return (
    <>
      {expenseId && (
        <ExpenseEllipsisMenuModals
          expense={expense}
          deleteModal={{
            closeModal,
            opened: openedModal === 'delete',
          }}
          paymentStatusModal={{
            closeModal,
            opened: openedModal === 'payment-status',
          }}
        />
      )}

      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          loading={isLoading}
          type="Expense"
          title={
            expense && (
              <Group wrap="nowrap" gap="xs" mt={rem(5)}>
                <Center w={20}>
                  {expense.connection.logo ? (
                    <Avatar
                      src={expense.connection.logo}
                      size="sm"
                      styles={{
                        placeholder: { visibility: 'hidden' },
                      }}
                      sx={(theme) => ({
                        border: `1px solid ${theme.colors.gray[2]}`,
                      })}
                    />
                  ) : (
                    <Icon icon="UserIcon" size={20} />
                  )}
                </Center>
                <Box>
                  <Title order={4} fw={500} component="p" m={0}>
                    {expense.connection.name || 'Manual expense'}
                  </Title>
                </Box>
              </Group>
            )
          }
          menuItems={
            expense && (
              <ExpenseEllipsisMenuItems
                expense={expense}
                openDeleteModal={() => setOpenedModal('delete')}
                openPaymentStatusModal={() => setOpenedModal('payment-status')}
              />
            )
          }
        />
        {!expense && !isLoading ? (
          'No expense details found'
        ) : (
          <Content expense={expense} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const Content = ({
  expense,
  isLoading,
}: { expense: Maybe<Expense>; isLoading: boolean }) => {
  if (!expense) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: CircleDollarIcon,
            title: 'Amount',
            text: formatCurrency(expense.centTotal / 100, expense.currency),
          },
          {
            icon: LoaderIcon,
            title: 'Status',
            text: <ExpensePaidStatusBadge paidStatus={expense.paidStatus} />,
          },
          {
            icon: CalendarEventIcon,
            title: 'Date',
            text: day(expense.date).format('MMM D, YYYY'),
          },
          {
            icon: CircleDollarIcon,
            title: 'Paid At',
            text: expense.paidAt
              ? day(expense.paidAt).format('MMM D, YYYY')
              : null,
          },
          {
            icon: HashtagIcon,
            title: 'Bill ID',
            text: expense.uniqueRef,
          },
          {
            icon: FolderOpenIcon,
            title: 'Bank/CC account',
            text: expense.paidAt
              ? expense.bankAccount.title || 'Non-Trust Account'
              : null,
          },
          {
            icon: UserIcon,
            title: 'Contact',
            text: expense.contact?.id ? expense.contact?.name : null,
          },
          {
            icon: NoteTextIcon,
            title: 'Description',
            text: expense.description,
          },
        ]}
      />

      <Lines lines={expense.lines} currency={expense.currency} />

      <JournalEntries
        currency={expense.currency}
        rowData={expense.journalEntries}
      />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

type ExpenseLine = Expense['lines'][number];

const Lines = ({
  lines,
  currency,
}: { lines: ExpenseLine[]; currency: string }) => {
  const { open: openReservation } = useReservationDetailDrawer();
  const { open: openListing } = useListingDetailDrawer();

  const total = useMemo(
    () =>
      formatCurrency(
        sum(lines, (x) => x.centTotal + (x.markup?.centTotal || 0)) / 100,
        currency
      ),
    [
      currency,
      ...lines.map((line) => line.centTotal + (line.markup?.centTotal || 0)),
    ]
  );

  const columns = useMemo<MRT_ColumnDef<ExpenseLine>[]>(
    () => [
      {
        header: 'Icon',
        accessorKey: 'id',
        size: 40,
        maxSize: 0,
        minSize: 40,
        grow: false,
        Cell: ({ row }) => {
          return (
            <Icon
              icon={
                row.original.reservation.id ? 'CalendarEventIcon' : 'HomeIcon'
              }
              size={20}
            />
          );
        },
      },
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const reservation = row.original.reservation;

          if (!reservation.id)
            return (
              <Box>
                <Text component="span" display="block" size="sm">
                  {row.original.listing.name}
                </Text>
                <Text component="span" display="block" size="xs" c="gray">
                  {row.original.description}
                </Text>
              </Box>
            );

          return (
            <Box>
              <Text component="span" display="block" size="sm">
                {reservation.guestName} - {reservation.confirmationCode}
              </Text>
              <Text component="span" display="block" size="xs" c="gray">
                {day(reservation.checkIn).format('ll')} -{' '}
                {day(reservation.checkOut).format('ll')}
                {reservation.guests && ` - ${reservation.guests} guests`}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'Split',
        accessorKey: 'centTotal',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        size: 100,
        Cell: ({ row }) => {
          const line = row.original;

          return (
            <HoverCard
              position="bottom-end"
              withArrow
              withinPortal
              width={250}
              shadow="md"
              radius="md"
              arrowOffset={10}
            >
              <HoverCard.Target>
                <Text>
                  {formatCurrency(
                    (row.original.centTotal +
                      (row.original.markup?.centTotal || 0)) /
                      100
                  )}
                </Text>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Stack gap="xs">
                  <Group wrap="nowrap" align="flex-start">
                    <Text c="gray" flex={1}>
                      Applied amount
                    </Text>
                    <Text ta="right" fw={500}>
                      {formatCurrency(line.centTotal / 100, currency)}
                    </Text>
                  </Group>
                  <Group wrap="nowrap" align="flex-start">
                    <Text c="gray" flex={1}>
                      Markup
                    </Text>
                    <Text ta="right" fw={500}>
                      {formatCurrency(
                        (line.markup?.centTotal || 0) / 100,
                        currency
                      )}
                    </Text>
                  </Group>
                </Stack>
              </HoverCard.Dropdown>
            </HoverCard>
          );
        },
      },
    ],
    []
  );

  return (
    <DrawerCollapsableTable
      title="Expense lines"
      rightSection={
        <Text fw={500} size="sm">
          {total}
        </Text>
      }
      onRowClick={{
        handler: (row) => {
          if (row.original.reservation.id)
            return openReservation(
              row.original.reservation.id,
              'overview',
              'push'
            );

          if (row.original.listing.id)
            return openListing(row.original.listing.id, 'overview', 'push');
        },
        disabled: (row) =>
          !row.original.reservation.id && !row.original.listing.id,
      }}
      rowData={lines}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No lines available
          </Text>
        </Center>
      )}
    />
  );
};

const JournalEntries = ({
  rowData = [],
  currency,
}: {
  rowData: Expense['journalEntries'];
  currency: string;
}) => {
  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  const columns = useMemo<
    MRT_ColumnDef<NonNullable<Expense['journalEntries']>[0]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <Box>
              <Text size="sm">{data.description}</Text>
              <Text size="xs" color="gray">
                {data.account}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'txnNum',
        accessorKey: 'txnNum',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const txnNum = row.original.txnNum;

          if (!txnNum) return null;

          return (
            <Text size="xs" c="gray" ta="right">
              {txnNum}
            </Text>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'amount',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            currency
          );
          return (
            <Box>
              <Text size="sm" ta="right">
                {amount}
              </Text>
              {row.original.txnAt && (
                <Text size="xs" c="gray" ta="right">
                  {day(row.original.txnAt).format('MMM DD, YYYY')}
                </Text>
              )}
            </Box>
          );
        },
      },
    ],
    [currency]
  );

  return (
    <DrawerCollapsableTable
      title={'Journal Entries'}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={rowData}
      columns={columns}
      defaultOpened
      emptyRowsFallback={'No journal entries found'}
    />
  );
};

const ExpensePaidStatusBadge = ({
  paidStatus,
}: {
  paidStatus: Maybe<paidStatus_enum>;
}) => {
  if (!paidStatus) return null;

  return (
    <Badge color={paidStatus === 'paid' ? 'green' : 'yellow'}>
      {toTitleCase(paidStatus)}
    </Badge>
  );
};
